<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manualforattrac3309"
		:showActionDelete="showDelete"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofquestio') }}
			<label>{{ $t('id') }}&nbsp;{{ model.Id }}</label>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import serviceEnums from '../../services/service/enums.service';
import serviceCommon from '../../services/service/common.service';

import { model, fields } from './faq.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Faq',
			routeParam: 'faqId',
			model,
			schema: {
				groups: fields,
			},
			showDelete: auth.IsAllowedForUser(enums.role.Admin),
		};
	},

	methods: {
		loadResources() {
			this.updateSchemaFields({
				CountryId: {
					values: this.$store.state.country.data,
				},
				TargetType: {
					values: serviceEnums.getEnumForVFG('faqTargetType'),
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.Id, this.$modal, this.deleteModel);
		},
	},
};
</script>
