import vueFormGenerator from 'vue-form-generator';
import { formatter } from '../../services/helpers/formatter';

export const model = {
	Id: 0,
	TargetType: null,
	QuestionSk: null,
	QuestionCz: null,
	QuestionEn: null,
	QuestionHu: null,
	QuestionPl: null,
	QuestionDe: null,
	QuestionRu: null,
	AnswerSk: null,
	AnswerCz: null,
	AnswerEn: null,
	AnswerHu: null,
	AnswerPl: null,
	AnswerDe: null,
	AnswerRu: null,
	CountryId: null,
	Order: null,
	IsActive: false,
	CreatedOn: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'TargetType',
				required: true,
				values: [],
				styleClasses: 'half-width',
				validator: vueFormGenerator.validators.integer,
				selectOptions: {
					noneSelectedText: '',
				},
				i18n: {
					label: 'questiontype',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'label',
				model: '',
				help:
					'nazov lokality: ~locality.name~' +
					'\n' +
					'url lokality: ~locality.url~' +
					'\n' +
					'link lokality: ~locality.link~' +
					'\n' +
					'nazov objektu: ~object.name~' +
					'\n' +
					'url objektu: ~object.url~' +
					'\n' +
					'link objektu: ~object.link~',
				get: function(model) {
					return (
						'~locality.name~' +
						'\n' +
						'~locality.url~' +
						'\n' +
						'~locality.link~' +
						'\n' +
						'~object.name~' +
						'\n' +
						'~object.url~' +
						'\n' +
						'~object.link~'
					);
				},
				i18n: {
					label: 'placeholders',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionSk',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questionsk2912',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerSk',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answersk',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionCz',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questioncz',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerCz',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answercz',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionPl',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questionpl',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerPl',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answerpl',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionEn',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questionen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerEn',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answeren',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionDe',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questionde',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerDe',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answerde',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'QuestionHu',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'questionhu',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AnswerHu',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'answerhu',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				min: 0,
				model: 'Order',
				required: true,
				styleClasses: 'quarter-width',
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'order2924',
				},
			},
			{
				type: 'switch',
				model: 'IsActive',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'active:',
					textOn: 'yes2205',
					textOff: 'no2206',
				},
			},
			{
				type: 'label',
				model: 'CreatedOn',
				get: function(model) {
					return model && model.CreatedOn ? formatter.dateTimeToString(model.CreatedOn) : '';
				},
				i18n: {
					label: 'created:',
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
		],
	},
];
